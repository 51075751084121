

.zoom {
  transition: transform .4s;
}

.zoom:hover {
  transform: scale(1.1);
}

.linkinsidetext {
  text-decoration: underline;
}

h2 span {
  background-color: rgb(255, 255, 255);
  border-radius: 3px
}

body {
  background: linear-gradient(#1b02fb, #00095b);
  font-family: 'Signika Negative', monospace;
  background-attachment: fixed;
}

a#youtube {
  background-image: url(../images/youtube50x50.png);
  display: block;
  width: 50px;
  height: 50px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 2px;
}

a#instagram {
  background-image: url(../images/instagram50x50.png);
  display: block;
  width: 50px;
  height: 50px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 2px;
}

a#facebook {
  background-image: url(../images/facebook50x50.png);
  display: block;
  width: 50px;
  height: 50px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 2px;
}

a#spotify {
  background-image: url(../images/spotify50x50.png);
  display: block;
  width: 50px;
  height: 50px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 2px;
}

a#apple_music {
  background-image: url(../images/apple50x50.png);
  display: block;
  width: 50px;
  height: 50px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 2px;
}

a#amazon_music {
  background-image: url(../images/amazonmusic50x50.png);
  display: block;
  width: 50px;
  height: 50px;
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 2px;
}